.fixed-dialog-wrapper {
  display: flex;
  flex-direction: column;

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid lightgray;
  }
}
