
.reusable-table-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 45px);
    padding: 10px 10px 0px 10px;
  
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      font-size: 1.4rem;
  
      .card-container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        font-size: 0.8rem;
  
        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0px 20px;
  
          &.green {
            border: 1px solid #1A6D2D;
            border-radius: 5px;
            color: #1A6D2D;
          }
  
          &.blue {
            border: 1px solid #004085;
            border-radius: 5px;
            color: #004085;
          }
        }
      }
    }
  
    .reusable-table-main-content {
      // display: flex;
      // justify-content: flex-start;
      height: 100%;
      /*overflow-x: hidden;*/
  
      .centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: -webkit-fill-available;
        padding: 10px;
        overflow: auto;
    
        &.top {
          justify-content: unset;
        }
  
        &.left {
          align-items: flex-start;
        }
      }

      .reusable-table-content-wrapper{
        height: calc(100% - 87px)
      }
    }
  }
   
  .totalTag:hover{
      color:#0094ff;
  }
  .shipstatus:hover {
      color: #0094ff;
  }
  