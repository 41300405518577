$borderRadius: 1rem;

.login-page {
  &-root {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 5rem 3rem 3rem 3rem;
    background: #970000;
    background: linear-gradient(90deg, #1E3C70 0% ,#970000 100%);
    position: relative;

    .logo {
      position: absolute;
      top: 2rem;
      left: 50%;
      z-index: 2;
      transform: translate(-50%);
  
      img {
        width: 200px;
      }
    }
  }

  &-card-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    min-width: 100%;
    border-radius: $borderRadius;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      color: #333;
  
      &.left {
        display: none;
      }
  
      &.right {
        width: 100%;
        min-width: 320px;
        padding: 2rem;
        border: none;
        border-radius: $borderRadius;
        background: white;

        .tabs-wrapper {
          width: 100%;
        }

        .form-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 100%;
          max-width: 400px;
          padding-bottom: 4rem;

          .form-title {
            display: flex;
            justify-content: center;
            width: 100%;
          }
  
          .form {
            width: 100%;
            margin-top: 4rem;
          }
  
          .form-button {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            position: relative;

            .button-wrapper {
              position: relative;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 720px) {
  .login-page {
    &-root {
      padding: 5rem;

      .logo {
        display: none;
      }
    }

    &-card-wrapper {
      flex-direction: row;

      .card {
        &.left {
          display: unset;
          width: 60%;
          min-width: 250px;
          border: none;
          border-top-left-radius: $borderRadius;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: $borderRadius;

          .logo-2 {
            position: absolute;
            top: 2rem;
            left: 2rem;
            z-index: 2;
        
            img {
              width: 200px;
            }
          }

          .carousel {
            height: inherit;

            .carousel-inner {
              height: inherit;

              .carousel-item {
                height: inherit;

                img {
                  width: 100%;
                  height: 100%;
                  max-height: calc(100vh - 10rem);
                  border-top-left-radius: $borderRadius;
                  border-bottom-right-radius: 0px;
                  border-bottom-left-radius: $borderRadius;
                  object-fit: cover;
                  filter: contrast(0.7) grayscale(0.7);
                }
              }
            }
          }
        }

        &.right {
          width: 40%;
          min-width: 450px;
          border-top-right-radius: $borderRadius;
          border-top-left-radius: 0px;
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: 0px;
        }
      }
    }
  }
}