.form-group {
  position: relative;
  margin-top: 1rem;

  .form-control {
    display: block;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

    &:disabled {
      color: lightgray;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    pointer-events: none;
  }
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder,
.form-control:disabled + .form-control-placeholder {
  font-size: 100%;
  transform: translate3d(-13px, -120%, 0);
  opacity: 1;
}
