.dashboard-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 20px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    font-size: 1.4rem;
  }

  .dashboard-main-content {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    .centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
      height: -moz-available;
      padding: 10px;
      overflow: auto;
    }
  }
}
