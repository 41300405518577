body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.WithStyles\(ForwardRef\(DialogContent\)\)-root-32 {
    padding: 0px 0px !important;
}

.input-group-text {
    padding: .375rem .15rem !important;
    background-color: unset !important;
    border: unset !important;
}

.MuiToggleButton-root {
    padding: 6px !important;
}

.-sort-desc {
    box-shadow: none !important;
}

.-sort-asc {
    box-shadow: none !important;
}



/*Box Page css */
.mrgLeft5 {
    margin-left:5px !important;
}
.paddingRight25 {
    padding-right:25px !important;
}

.boxesDelete {
    margin: 20px;
    text-align: center;
    font-size: 15px;
    font-weight: 500
}
.boxesDeleteDialogBtn {
    background-color: #cc2525 !important;
    float: right !important;
}
.dltBtnColor {
    background-color: #cc2525 !important;
}
.addBoxPagginDiv {
    padding: 5px 12px !important;
}

.marginLF0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.paddingTop {
padding-top:7px !important;
}
.paddingTop13 {
    padding-top:13px !important;
}

.addBoxBtnClose {
    float: right !important;
    margin: 4px !important;
    border: 1px solid !important
}
.addBoxBtnSave {
    float: right !important;
    margin: 4px !important;
}

.paper {
    margin-top: 20px !important;
}

.toggleBtn {
    height: 25px !important;
    float: right !important;
}

.toggleGroupOuter {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin: 5px !important;
}

.iconBtn {
    padding: 3px !important
}

.pagination {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    background-color: #F3F3F3 !important;
    padding: 6px !important;
    margin: 0 10.2px !important;
}

.paginItem {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.indeCb {
    text-align: center !important;
    margin: 0px -2px !important;
}

.centerCell {
    font-size: 0.8rem !important;
}

.failedMsg {
    margin-top: 18% !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: red !important;
}

.loadingMsg {
    margin-top: 18% !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.progressGrey {
    color: grey !important;
    margin-right: 5px !important;
}

.noBoxMsg {
    margin-top: 18% !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.conAccBtn {
    margin-bottom: 10px !important;
    text-transform: capitalize !important;
    margin-left: 5px !important;
    align-self: center !important;
}

.editBtn {
    padding: 2px !important;
    margin-left: 2px !important;
}

.deleteBtn {
    padding: 1px !important;
}

.deleteSvg {
    font-size: 1.5rem !important;
}

.titleText {
    margin-left: 5px !important;
    font-weight: 700 !important;
    align-self: center !important;
    font-size: 22px !important;
    margin: 10px !important;
}

.addBoxtoggleBtn {
    height: 25px; 
} 

.addBoxtoggleBtn .MuiToggleButton-label {
        font-size: smaller
}

.textCenter {
    text-align: center !important
}
 
.tableOuter {
    margin: 0 10px !important;
    width: calc(100% - 20px) !important;
    height: calc(100vh - 264px) !important;
    overflow-y: scroll !important;
}

/*
.reactTable {
    width: 100% !important;
    border: none !important,
}

.reactTable, th {
    position: sticky !important;
    top: 0 !important;
    z-index: 10 !important;
    padding: 8px 8px !important;
    border-left: 1px solid #DDDDDD !important;
    background-color: #F3F3F3 !important;
}
.reactTable, td {
    padding: 5px 8px;
    border-bottom: 1px solid #DDDDDD;
    background-color: #ffff !important;
}
.wd180 {
    width: 180px !important
}
.reactTable:first-child {
    border-left: 3px solid #DDDDDD !important;
    text-align: left !important;
    width: 20px !important;
}*/

*, ::after, ::before {
    box-sizing: border-box;
}
  