.rounded-card {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 4px 4px 8px lightgray;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 55px;
    padding: 0px 10px;
    border-bottom: 1px solid lightgray;
    white-space: nowrap;
    overflow: hidden;

    &-title {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}